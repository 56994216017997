import {request} from "../../util/Request"
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'invoice_number', label: this.$t('title.invoiceNumber'), show: true},
                    {id: 'supplier', label: this.$t('title.supplierCarrier'), show: true},
                    {id: 'price', label: this.$t('title.price'), show: true},
                    {id: 'invoice_date', label: this.$t('title.invoiceDate'), show: true},
                ],
                headers: {
                    [this.$t('column.invoiceNumber')]: 'invoice_number',
                    [this.$t('column.supplierCarrier')]: 'supplier',
                    [this.$t('column.price')]: 'price',
                    [this.$t('column.invoiceDate')]: 'invoice_date',
                }
            },
        }
    },
    methods: {
        async handleSingleExcelExportClick(id) {
            try {
                const response = await request({
                    url: `/pre/invoices/detail/${id}`,
                })

                const {data} = response

                this.exportModal.headers = {
                    '#': '#',
                    [this.$t('column.client')]: 'client',
                    [this.$t('column.clientOwner')]: 'client_owner',
                    [this.$t('column.brand')]: 'brand',
                    [this.$t('column.model')]: 'model',
                    [this.$t('column.vinNumber')]: 'vin_number',
                    [this.$t('column.transportPrice')]: 'price',
                    [this.$t('column.additionalCost')]: 'additional_price',
                    [this.$t('column.total')]: 'total',
                }

                const rows = []

                rows.push({ '#': this.$t('title.invoiceNumber'), client: data.invoice_number,})
                rows.push({ '#': this.$t('title.invoiceDate'), client: data.invoice_date})
                rows.push({ '#': this.$t('title.total'), client: data.price})
                rows.push({ '#': this.$t('title.totalLoads'), client: data.invoice_items.length})
                rows.push({})

                data.invoice_items.map((load, lIndex) => {
                    rows.push({ '#': this.$t('title.load'), client: load.item.load_number })
                    rows.push({ '#': this.$t('title.route'), client: (load.item.route || {}).name })
                    rows.push({ '#': this.$t('title.carrier'), client: load.item.supplier_carrier.name })
                    rows.push({ '#': this.$t('title.actualLoadingTime'), client: this.$global.utcDateToLocalDate(load.item.actual_loading_time) })
                    rows.push({ '#': this.$t('title.actualUnLoadingTime'), client: this.$global.utcDateToLocalDate(load.item.actual_unloading_time) })

                    load.item.sub_items.map((order, lIIndex) => {
                        rows.push({
                            '#': "ORDER - "+(lIIndex + 1),
                            client: order.order.client.company_name,
                            client_owner: (order.order.client_owner ? order.order.client_owner.company_name : order.order.client.company_name),
                            brand: order.order.brand.title,
                            model: order.order.model.title,
                            vin_number: order.order.vin_number,
                            price: order.price,
                            additional_price: 0,
                            total: order.price +' ( '+ order.currency.label +' )',
                        })
                    })
                    rows.push({})
                    rows.push({})
                })

                return rows;
            } catch (e) {
                console.log(e)
            }

            return [];
        },
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })

            this.exportModal.headers = headers

            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams, sortField: 'invoice_user_id', sortOrder: 'DESC'}
            const response = await request({
                method: "get",
                url: 'pre/invoices/excel',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response
            const rows = _.map(data, (item, index) => _.pick({
                invoice_number: this.$global.val(item.invoice_number),
                supplier: this.$global.val((item.supplier || {}).name),
                price: this.$global.val(item.price),
                invoice_date: (item.invoice_date ? this.$global.val(this.$global.dateFormat(item.invoice_date)) : ""),
            }, columns))

            const sortedRows = _.sortBy(rows, ['supplier']);
            const newRows = [];

            _.map(sortedRows, (item, index) => {
                if (index <= 0) {
                    newRows.push({invoice_number: item.supplier})
                }

                newRows.push(item)

                if (sortedRows.length !== (index + 1) && item.supplier !== sortedRows[index + 1].supplier) {
                    newRows.push({})
                    newRows.push({invoice_number: sortedRows[index + 1].supplier})
                }
            })
            return newRows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
    },
}
