<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-capitalize">
                                        {{$t("title.invoiceDetail")}} #{{detailLoad.invoice_number}}
                                    </span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <json-excel
                                        v-if="$global.hasPermission('preinvoicesview')"
                                        class="btn btn-outline-info btn-sm"
                                        :fields="exportModal.headers"
                                        worksheet="pre-invoice"
                                        :fetch="() => handleSingleExcelExportClick($route.query.oToken)"
                                        name="preInvoice.xls">
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                                    <i class="fa fa-file-excel-o"></i>
                                </json-excel>
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                        <div class="mt-3">
                            <div class="box border rounded p-4">
                                <div class="clearfix">
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12">
                                            <h6><span class="text-capitalize">{{$t('title.invoiceDate')}}</span></h6>
                                        </b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{ $global.utcDateToLocalDate(detailLoad.invoice_date, 'DD.MM.YYYY', 'YYYY-MM-DD')}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12">
                                            <h6><span class="text-capitalize">{{$t('title.total')}}</span></h6>
                                        </b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{ detailLoad.price }}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12">
                                            <h6><span class="text-capitalize">{{$t('title.totalLoads')}}</span></h6>
                                        </b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{ detailLoad.invoice_items.length }}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="box border rounded p-4"  v-for="load in detailLoad.invoice_items">
                                <h4>
                                    <span class="text-capitalize">{{$t('title.loadNumber')}} {{load.item.load_number}}</span>
                                    <br />
                                    <small>
                                        <transport-name :type="load.object_type"></transport-name>
                                    </small>
                                </h4>
                                <b-col col="12">
                                    <b-row class="mt-2">
                                        <b-col md="6" lg="6" sm="12">
                                            {{$t('title.route')}}
                                        </b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{(load.item.route || {}).name}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row class="mt-2">
                                        <b-col md="6" lg="6" sm="12">
                                            {{$t('title.carrier')}}
                                        </b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{(load.item.supplier_carrier || {}).name}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row class="mt-2">
                                        <b-col md="6" lg="6" sm="12">
                                            {{$t('title.actualLoadingTime')}}
                                        </b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{$global.utcDateToLocalDate(load.item.actual_loading_time)}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row class="mt-2">
                                        <b-col md="6" lg="6" sm="12">
                                            {{$t('title.actualUnLoadingTime')}}
                                        </b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{$global.utcDateToLocalDate(load.item.actual_unloading_time)}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row class="mt-2">
                                        <b-col sm="12" md="12" lg="12">
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <td>#</td>
                                                        <td>{{$t('title.client')}}</td>
                                                        <td>{{$t('title.clientOwner')}}</td>
                                                        <td>{{$t('title.brand')}}</td>
                                                        <td>{{$t('title.model')}}</td>
                                                        <td>{{$t('title.vinNumber')}}</td>
                                                        <td>{{$t('title.transportPrice')}}</td>
                                                        <td>{{$t('title.additionalCost')}}</td>
                                                        <td>{{$t('title.total')}}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(order, index) in load.item.sub_items">
                                                        <td>{{index + 1}}</td>
                                                        <td>{{order.order.client.company_name}}</td>
                                                        <td>
                                                            <span v-if="order.order.owner_client">
                                                                {{order.order.owner_client.company_name}}
                                                            </span>
                                                            <span v-else>
                                                                {{order.order.client.company_name}}
                                                            </span>
                                                        </td>
                                                        <td>{{order.order.brand.title}}</td>
                                                        <td>{{order.order.model.title}}</td>
                                                        <td>{{order.order.vin_number}}</td>
                                                        <td>{{order.price}}</td>
                                                        <td>0</td>
                                                        <td>{{order.price}} ({{(order.currency || {}).label}})</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {request} from "../../util/Request"
    import ExcelExportMixin from "./ExcelExportMixin"
    import JsonExcel from "vue-json-excel"
    import {mapState} from 'vuex'

    export default {
        mixins: [ExcelExportMixin],
        props: ['handleCloseOperation'],
        components: {
            JsonExcel
        },
        data() {
            return {
                detailLoad: null,
                selectedAttachment: [],
                dropdowns: {
                    currencies: [],
                }
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            async getDetail(id) {
                try {
                    const response = await request({
                        url: `/pre/invoices/detail/${id}`,
                    })

                    const {data} = response
                    this.detailLoad = data
                } catch (e) {

                }
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
